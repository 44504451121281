.configContainer{
    h4{
        color: #4fb1c3;
        font-size: 16px;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
        border: none;
      background: transparent;
      margin-right: 10px;
      padding-right: 10px;
      line-height: 20px;
      display: grid;
    place-content: center;
    overflow: hidden;
      padding: 9px;
      cursor: pointer;
      background: #32a0b4;
      color: white;
        }
    }

    table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  th:last-of-type{
    text-align: center;
  }
  th{
    font-size: 14px;
  }
  td{
    font-size: 12px;
  }
  td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  .action{
    display: flex;
    gap: 8px;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    .delete{ 
        background: red;
        width: 30px;
        height: 30px;
        font-size: 14px;
        border-radius: 50%;
        display: grid;
        place-content: center;
        color: whitesmoke;
       
    }
     .update{
        background: hsl(147, 100%, 35%);
        width: 30px;
        height: 30px;
        font-size: 14px;
          border-radius: 50%;
        display: grid;
        place-content: center;
        color: whitesmoke;
    }
    .view_single{
        background: #32a0b4;
        width: 30px;
        height: 30px;
        font-size: 14px;
          border-radius: 50%;
        display: grid;
        place-content: center;
        color: whitesmoke;
    }

  }
  
}

tr:nth-child(even) {
  background-color: #00000008;
}
}

.MuiSvgIcon-fontSizeMedium{
    font-size: 18px;
}
}
.edit_config{
    h4{
        button{
            height: 40px;
            width: 40px;
            color: whitesmoke;
            background: red;
            border-radius: 50%;
         }
    }
   form{
    .group{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    .single{
        font-size: 14px;
        margin-bottom: 10px;
       label{
         display: block;
         margin-bottom: 4px;
         color: rgba(0,0,0,0.85);
         font-size: 14px;
         display: block;
         width: 100%;
       } 
       input{
        font-size: 13px;
        width: 300px;
        padding: 10px;
        outline: none;
        border-radius: 4px;
    height: 42px;
    padding: 6px 10px;
    line-height: 1.5;
    color: rgb(89,89,89);
    background-color: rgb(255,255,255);
    border: 1px solid rgb(233,233,233);
        &:focus{
            outline: none;
            border-radius: 2px;
            border: 1px solid #32a0b4;
        }
       }
    }
    .copyLink{
        color: #32a0b4;
        font-weight:bold;
        span{
            margin-left: 10px;
            cursor: pointer;
        }
        .MuiSvgIcon-fontSizeMedium{
            font-size: 20px;
        }
    }
    .action{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        button{
            width: 120px;
            border: none;
            padding: 10px;
            cursor: pointer;
            color: whitesmoke;
            background: #32a0b4;
            display: grid;
    place-content: center;
    overflow: hidden;
            &.cancel{
              background: red;
            }
        }
    }
   }
}
.add_new_config{
    h4{
        button{
            height: 40px;
            width: 40px;
            color: whitesmoke;
            background: red;
            border-radius: 50%;
         }
    }
    .group{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    .single{
        font-size: 14px;
        margin-bottom: 10px;
       label{
         display: block;
         margin-bottom: 4px;
         color: rgba(0,0,0,0.85);
         font-size: 14px;
         display: block;
         width: 100%;
       } 
       input{
        font-size: 13px;
        width: 300px;
        padding: 10px;
        border-radius: 4px;
    height: 42px;
    padding: 6px 10px;
    line-height: 1.5;
    color: rgb(89,89,89);
    background-color: rgb(255,255,255);
    border: 1px solid rgb(233,233,233);
        &:focus{
            outline: none;
            border-radius: 4px;
            border: 1px solid #32a0b4;
        }
       }
    }
    .copyLink{
        color: #32a0b4;
        font-weight:bold;
        span{
            margin-left: 10px;
            cursor: pointer;
        }
        .MuiSvgIcon-fontSizeMedium{
            font-size: 20px;
        }
    }
    .action{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        button{
            width: 120px;
            border: none;
            padding: 10px;
            cursor: pointer;
            color: whitesmoke;
            background: #32a0b4;
            display: grid;
    place-content: center;
    overflow: hidden;
            &.cancel{
              background: red;
            }
        }
    }
}

