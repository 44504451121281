.container{
    .contentContainerPets{
        position: relative;
        width: 100%;
        min-height: 650px;
        gap: 10px;
        box-sizing: border-box; 
        margin-bottom: 113px;
        background: #F1F4F8;
        padding: 40px;
        @media screen and (max-width:500px) {
          padding: 10px;  
        }
       .pet_container_action{
        position: relative;
          .Intro_header{
            display: flex;
            justify-content: space-between;
           @media screen and (max-width:500px) {
            flex-direction: column;
            align-items: center;
            position: sticky;
            top: 68px;
            background: white;
            margin-left: -10px;
            margin-right: -10px;
            padding-bottom: 12px
           }
        }
         .medical-modal{
            background:hsla(226, 27%, 15%, 0.461);
            position: fixed;
            z-index: 1000;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            .record-view-table{
                background: white;
                position: relative;
                width: 659px;
                height: 90%;
                overflow-y: auto;
                top: 5%;
                border-radius: 6px;
                margin:auto;
                padding: 20px;
                @media screen and (max-width: 670px) {
                    width: 80%;
                }
            }
         }
        } 
        .page_action{
            display: flex;
            gap: 20px;
            .list_switcher{
                display: flex;
                padding: 2px;
                width: 68px;
                height: 36px;
                background: #E4E6E9;
                border-radius: 8px;
                button{
                  flex: 1;
                  border: none;
                   border-radius: 8px;
                   background: transparent;
                   cursor: pointer;
                   &.active{
                    background: white !important;
                   }
                   svg{
                    pointer-events: none;
                   }
                }
            }
            .add_new_pet{
                button{
                    width: 135px;
                    height: 40px;
                    border: none;
                    background: #5AB1BB;
                    color: white;
                    border-radius: 8px;
                    cursor: pointer;
                }
            }
        }
        .data_container{
            margin-top: 40px;
            .grid_container{
               display: grid;
               grid-template-columns: repeat(auto-fill, minmax(335px,1fr));
               gap: 32px;
               .card{
                background: #FFFFFF;
                min-height: 438px;
                border-radius: 6px;
                box-shadow: 0px 10px 48px rgba(213, 222, 234, 0.84);
                padding: 20px;
                .card_intro{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    .image_container{
                        height: 120px;
                        width: 120px;
                        border-radius: 50%;
                        img{
                            height: 100%;
                            width:100%;
                            border-radius: 50%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    button{
                        border: none;
                        background: transparent;
                        color: #5AB1BB;
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        margin-bottom: 14px;
                        cursor: pointer;
                    }
                    
                    h3{
                        font-size: 20px;
                        font-weight: 500;
                        color: #1C2232;
                        margin: 8px 0;
                        margin-bottom: 5px;
                        
                    }
                }
                ul{
                    list-style: none;
                    cursor: pointer;
                    li{ padding: 20px 0;
                        display: flex;
                        justify-content: space-between;
                        color: #1C2232;
                        border-bottom: 1px solid rgba(213, 222, 234, 0.84);
                        &:first-of-type{
                        border-top: 1px solid rgba(213, 222, 234, 0.84);
                        }
                        &:last-of-type{
                            border-bottom: none;
                            }
                            span{
                                font-size: 14px;
                                color: #2E3445;
                                font-weight: 400;
                                display: flex;
                                align-items: center;
                                gap: 4px;
                            }
                    }
                    &.skeleton_List{
                        li{
                            span{
                                width: 100%;
                            }
                        }
                    }
                }
               }
            }
            .list_container{
                width: 100%;
                overflow-y: hidden;
                overflow-x: auto;
                .filter{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 34px;
                    .category{
                        ul{
                            display: flex;
                            list-style: none;
                            gap: 34px;
                            flex-wrap: wrap;
                            li{
                                padding-bottom: 15px;
                                cursor: pointer;
                                &.activeCtg{
                                    font-weight: bold;
                                    border-bottom: 2px solid #5ab1bb;
                                }
                                &:hover{
                                    font-weight: bold;
                                    border-bottom: 2px solid #5ab1bb;
                                }
                            }
                        }
                    }
                    .category-dropdown{
                        min-width: 120px;
                        select{
                        outline: none;
                        border: none;
                        padding: 10px;
                        border-radius: 6px;
                        color:#4A5267;
                        option{
                            div{
                                display: flex;
                                gap: 2px;
                                img{
                                    width: 40px;
                                    height: 100%;
                                }
                                .petName{
                                    color: black;
                                }
                            }
                        }
                        }
                    }
                }
                table {
                    border-collapse: collapse;
                    width: 100%;
                    th, td {
                        text-align: left;
                        padding: 8px;
                        span.color-code-1,span.color-code-2,span.color-code-3,span.color-code-4,span.color-code-5{
                            font-weight: bold;
                            text-align: center;
                            padding: 4px 8px;
                            border-radius: 4px;
                            width: 120px;
                        }
                        &>.color-code-1{
                         color: #E965BE;
                         background: #e965bd34
                         ;
                        }
                        &>.color-code-2{
                            color: #4497A0;
                            background: #4497a05b;
                            ;
                           }
                        &>.color-code-3{
                            color: #4B4DED;
                            background:#4b4eed3a;
                            ;
                           }
                        &>.color-code-4{
                            color: #ED994B;
                            background: #ed994b3e;
                            ;
                           }
                        &>.color-code-5{
                            color: #95D278;
                            background: #95d27841;
                            ;
                           }
                      }
                      td.specialtd{
                        color: #5ab1bb !important;
                        gap: 18px;
                        display: flex;
                        align-items: center;
                      }
                      tr{ 
                           height: 68px;
                           width: 100%;
                           display: flex;
                           background: white;
                           border-radius: 6px;
                           align-items: center;
                           
                           margin-bottom: 16px;
                           &:first-of-type{
                            background: transparent;
                            box-shadow: none;
                            margin-bottom: 0px;
                            display: flex;
                           }
                      }
                      th{
                        flex: 1;
                        padding: 28px;
                        font-weight: 500;
                        font-size: 12px;
                        color: #1C2232;
                      }
                      td{
                        color: #2E3445;
                        font-weight: 400;
                        font-size: 14px;
                        flex: 1;
                        padding: 28px;
                        span{
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }
                        div{
                         height: 36px;
                         width: 36px; 
                         border-radius: 50%; 
                         img{
                            height: 100%;
                            width:  100%;
                            object-fit: cover;
                            object-position: center;
                         border-radius: 50%; 
                         }
                        }
                        &.exceptional_img{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        &.exceptional_img_record{
                            display: flex;
                            align-items: center;
                            gap: 20px;
                        }
                        &.exceptional_name{
                            color:#1C2232;
                            font-weight: 
                            500;
                            font-size: 16px;

                        }
                        &:last-of-type{
                            display: flex;
                            justify-content: flex-end;
                            svg{
                                cursor: pointer;
                            }
                        }
                        
                      }
                  }
                  
                  
            }
        }
    }
}
.pagination{
    display: flex;
    justify-content: center;
    gap: 8px;
    div{
        height: 40px;
        width: 40px;
        display: grid;
        place-content: center;
        font-size: 14px;
        border-radius: 6px;
        color: #5AB1BB;
        border: 1px solid #E4E6E9;
        background-color: white;
        cursor: pointer;
        &.active-page-link{
            background-color: #5AB1BB;
            color: white;
        }
        svg{
            font-size: 14px;
            pointer-events: none
            ;
        }
    }
}