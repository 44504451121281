.container{
    width: 100%;
    background:#f1f4f8;
}
.contentContainer{
    
    position: relative;
    width: 100%;
    min-height: 650px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    box-sizing: border-box; 
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 113px;
    background: #f1f4f8;
    @media screen and (max-width: 500px) {
      padding-left:0;
      padding-right:0;
    }
}

   @media screen and (max-width: 600px ) {
       .contentContainer{
        flex-direction:column;
       } 
    }
.selectStyles{
  height: 42px;
}
.form-group>div{
    flex: 1;
}
.divider{
  margin-top: 40px !important;
  margin-bottom: 30px !important;
}
.contactWith{
  display: flex;
  justify-content: flex-end;
 
}
.contactWith>svg{
    margin-right: 4px;
    align-items: center;
}
.prof-dots{
  @media screen and (max-width: 500px) {
    display:none;
  }
}




