.hide{
    display: none;
}
.show{
    display: block;
}
.selectStyles{
    margin-bottom: 15px;
    border: 1px solid #E4E6E9;
    border-radius: 4px;
    color: rgb(89, 89, 89);
    outline: none;
}
.selectStyles:focus{
  border-color: #40a9ff;
}
.LinksHeader{

    position:sticky;
    top:70px;
    display: flex;
    justify-content: space-between;
    background:#F1F4F8;
    height: 80px;
    align-items: center;
    z-index: 1;
}
.LinksHeader>button{
    background: none;
    border: none;
    padding-bottom: 8px;
    cursor: pointer;
}
.LinksHeader>button:hover,.LinksHeader>button.activebtn{
    background: none;
    border: none;
    padding-bottom: 8px;
    border-bottom: 2px solid #4497A0;
}