.header_intro{
   display: flex;
   align-items: center;
   justify-content: center;
   h1{
    font-weight: 500;
font-size: 24px;
   }
   svg{
    position: absolute;
    right: 20px;
    cursor: pointer;
   }
}

.download_div{
    display: flex;
    justify-content: left;
    align-items: center;
     button{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        outline: none;
        border-radius: 4px;
        border: 2px solid #5AB1BB;
        padding: 8px;
        color: #5AB1BB;
        background: none;
        cursor: pointer;
        margin-bottom: 8px;
     }

}

.pdf_container{
    border: 2px solid #80808017;
    height: 100%;
    padding: 10px;
    border-radius: 4px;
}