.settingsContainer{
  .settings_header{
    margin-bottom: 10px;
    button{
      border: none;
      background: transparent;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 2px solid #4fb1c3;
      line-height: 20px;
      padding: 9px;
      cursor: pointer;
      // temporary
      font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 0;
      &:last-of-type{
        border: none;
      }
      &.active{
        background: #32a0b4;
        color: white;
      }
    }
  }
  .hrdivider{  
    border: 1px solid #ebebeba1;
  }
}