.is-active{
    color: red;
}
.small-sidebar-hide{
    margin-left: -250px !important;
    overflow: hidden;
    position: relative;
}

.large-logo{
    transition: all 0.5s ease;
   display: block;
}
.small-logo{
    transition: all 0.5s ease;
    display: none;
 }
.small-sidebar .small-logo{
  display: block;
}
.small-sidebar .large-logo{
    display: none !important;
}

.iconSidebar{
    pointer-events: none;
}
.menu_container{
    a{
        width: 100%;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        gap: 12px;
        text-decoration: none;
        height: 40px;
    }
    
}
.active{
    border-radius: 6px;
    background: rgba(90, 177, 187, 0.1) !important;
    color: #5AB1BB !important;
}
.addpetspecial{
    color: white !important;
    background: #5AB1BB !important;
}
.back-arrow{
    display: none;
    @media screen and (max-width:500px) {
        display: block;
    }
    svg{
        font-size: 30px;
    }
}