@import url('https://fonts.googleapis.com/css2?family=Erica+One&family=Poppins:wght@600&display=swap');
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #079ace;
  border: 0px none #ffffff;
  border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
  background: #079ace;
}
::-webkit-scrollbar-thumb:active {
  background: #079ace;
}
::-webkit-scrollbar-track {
  background: #303345;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #303345;
}
::-webkit-scrollbar-track:active {
  background: #303345;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.title{
  color:#4fb1c3;
  font-weight: bold;
  font-family: 'Erica One', cursive;
  font-family: 'Poppins', sans-serif;
}
.uppy-Dashboard-inner{
  margin-bottom: 10px;
}
.changePassword{
  cursor: pointer !important;
}

.web-action{
  @media (max-width: 600px) {
    display: none !important;
  }
}
