.pet_info_container{
    padding: 40px;
    @media screen and (max-width: 500px) {
        padding: 10px;
    }
    .pet_info{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
        @media screen and (max-width: 500px) {
            display: block;
        }
        .pet_info_back{
            display:flex ;align-items: center;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: #5AB1BB;
            cursor: pointer;
            svg{
                pointer-events: none;
                margin-right: 8px;
            }
        }
        .pet_info_body{
            h1{font-weight: 500;
            font-size: 32px;
            color: #1C2232;
        text-align: center;
        margin-bottom: 20px;
            }
            form{
                min-width: 514px;
                min-height: 944px;
                background: #FFFFFF;
                border-radius: 6px;
                padding: 32px;
                @media screen and (max-width: 500px) {
                    min-width: 250px;
                   }
                .singleInput{
                    margin-bottom: 22px;
                    label{
                        font-weight: 500;
                        font-size: 12px;
                        padding-left: 16px;
                        &.specialLabel{
                            padding-left: 0px;
                        } 
                    }
                    ::placeholder { 
                        color: #ABB2C8;
                     }
                    input{
                        padding: 14px 16px;                        
                        width: 100%;
                        height: 48px;
                        background: #FFFFFF;
                        border: 1px solid #E4E6E9;
                        border-radius: 6px;
                        margin-top: 6px;
                        color: #1C2232;
                        outline: none;
                        
                        &:focus{
                            border: 2px solid #5AB1BB !important ;
                        }
                        &.radioSpacial{
                            height: 20px;
                            width: 20px;
                            padding: 0;   
                        }
                        
                    }
                    select{
                        padding: 14px 16px; 
                        outline: none;                       
                        width: 100%;
                        height: 48px;
                        background: #FFFFFF;
                        border: 1px solid #E4E6E9;
                        border-radius: 6px;
                        margin-top: 6px;
                        &:focus{
                            border: 2px solid #5AB1BB;
                        }
                    }
                    .radio_group{
                        display: flex;
                        justify-content: space-between;
                        gap: 10px;
                        div{
                        transition: all 0.3s linear;
                        padding: 14px 16px;                        
                        width: 100%;
                        height: 48px;
                        background: #FFFFFF;
                        border: 1px solid #E4E6E9;
                        border-radius: 6px;
                        margin-top: 6px;
                        display: flex;
                        align-items: center;
                        }
                    }

                }
                .imageUploader{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 28px;
                    justify-content: center;
                    align-items: center;
                    .img_container{
                        height: 120px;
                        width: 120px;
                        border-radius: 50%;
                        background: black;
                        margin-bottom: 15px;
                        img{
                            height: 100%;
                            width: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                            object-position: center;
                        }
                     }
                    label{
                        h4{
                            text-align: center;
                            display: flex;
                            align-items: center;
                            gap:8px;
                            font-size: 14px;
                            text-align: center;
                            color: #5AB1BB;
                            font-weight: 500;
                            cursor: pointer;
                        }
                    }
                    .edit_after_view{
                        background: transparent;
                        border: none;
                        color: #5AB1BB;
                        cursor: pointer;
                    }
                }
                .actionbtn{
                    display: flex;
                    gap:16px;
                    width: 100%;
                    button{
                    height: 48px;
                    color: #5AB1BB;;
                    background: transparent;
                    border-radius: 8px;
                    border:none;
                    flex: 1;
                    cursor: pointer;
                    }
                    .cancel{
                      border: 2px solid #5AB1BB;
                    }
                    .save{
                    background: #5AB1BB;
                    color: whitesmoke;
                    }
                }

             }
        }

    }
    .radioHighlight{
        border: 2px solid #5AB1BB !important;  
        background: rgba(90, 177, 187, 0.1)!important;  
    }
    
}
.frmSwitcher{
    min-width: 172px;
    height: 33px;
    background: #E4E6E9;
    border-radius: 8px;
    display: flex;
    padding: 2px;
    margin-top: 22px;
    button{
        height: 100%;
        width: 100%;
        border: none; 
        color: #4A5267;
        padding: 8px;
        background: transparent;
        cursor: pointer;
        border-right: 3px solid white;
        &:last-of-type{
        border: none;
        }
        &.activebtn{
            background: white;
            color: black;
            border: none;
            border-radius:0;
          }
          &.activebtn:first-of-type{
            background: white;
            color: black;
            border: none;
            border-radius:8px 0 0px 8px;
          }
        &.activebtn:last-of-type{
          background: white;
          color: black;
          border: none;
          border-radius:0 8px 8px 0;
        }
    }
}
.groupInput{
    display: flex;
    gap: 4px;
    &>.singleInput{
        flex: 1 !important;
    }
}
.petfiles{
    list-style: none;
    li{
        display: flex;
        align-items: center;
        a{
        display: flex;
        align-items: center;
        gap: 8px;
        }
    }
}
.columnContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    @media screen and (max-width: 500px) {
       grid-template-columns: 1fr;
      }
}
.filesLists{
    li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #1c2232;
        padding: 8px ;
        border-radius: 8px;
        margin-bottom: 20px;
        color: white;
         font-size: 12px;
         h6{
         font-size: 12px;
         }
        svg{
            color:#cde70a !important 
        }
        a{
            color: #5AB1BB !important;
        }
    }
    
}

.downloadBtn{
    background: transparent;
    border: none;
    cursor: pointer;
}