.wrapperContainer{
    .wrapper_body{
      min-height: 500px;
      width: calc(100% - 40px);
      background: white;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      padding: 20px;
      overflow-y: auto;
    }
  }