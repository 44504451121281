.pageheader{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent
  }
  .pageheader div{
      width: 100%;
      height: 1px;
      background-color: transparent
  }
  .pageheader h1{
     padding-left: 8px;
     line-height: 40px;
     padding-right: 10px;
     font-size: 32px;
     font-weight: bold;
     color: #1C2232;

  }