.avatar{
    margin-left: 20px !important;
    position: relative;
}
.online{
    height: 14px;
    width: 14px;
    border-radius: 100%;
    background-color: hsl(147, 100%, 50%);
    position: absolute;
    top: 2px;
    right: -4px;
    z-index: 10;
    animation: beat 0.5s linear alternate infinite;
}
@keyframes beat {
    0%{
    transform: scale(1);
    }
    50%{
    transform: scale(1.1);
        
    }
    100%{
        transform: scale(0.9);
            
        }
    
}
.menu{
    cursor: pointer;
}
.revealSettings{
    visibility: visible;
    opacity: 1;
}
.hideSettings{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}